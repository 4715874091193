import { ChangeDetectionStrategy, Component, DestroyRef, type OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Permission } from '@sds/permissions';
import { SharedModule } from '@sds/shared';
import { firstValueFrom } from 'rxjs';
import { MenuItem } from '../sidenav/menu-item/menu-item.model';
import { SidenavStore } from '../sidenav/ngrx/sidenav.store';

@Component({
  imports: [SharedModule, RouterModule],
  selector: 'sds-route-handler',
  templateUrl: './route-handler.component.html',
  styleUrls: ['./route-handler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RouteHandlerComponent implements OnInit {
  sidenavStore = inject(SidenavStore);
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private translateService = inject(TranslateService);

  async ngOnInit() {
    // Hack. Angular is having problem with loading translation files in APP_INITIALIZER
    // see https://github.com/angular/angular/issues/23279
    await firstValueFrom(this.translateService.get('foo'));

    // Set Sidenav Currently Open on Page load
    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sidenavStore.setCurrentlyOpenByRoute(event.urlAfterRedirects);
      }
    });
    this.createSidenavItems();
  }

  private createSidenavItems() {
    // Define Menu Items here
    // Top Level Item (The item to click on so the dropdown opens)
    const administration = new MenuItem({
      name: 'menu.administration.label',
      icon: 'sl:administration',
      route: '/administration',
      subItems: [
        new MenuItem({
          name: 'menu.administration.portals',
          route: '/administration/portals',
          icon: 'sl:portals',
          subItems: [],
          requiredPermissions: [Permission.PortalMenuRead],
          id: 'administration-portals'
        }),
        new MenuItem({
          name: 'menu.administration.fleets',
          route: '/administration/fleets',
          icon: 'sl:fleet-management',
          subItems: [],
          requiredPermissions: [Permission.FleetsRead],
          id: 'administration-fleets'
        }),
        new MenuItem({
          name: 'menu.administration.plants',
          route: '/administration/plants',
          icon: 'sl:plant-management',
          subItems: [],
          requiredPermissions: [Permission.PlantsRead],
          id: 'administration-plants'
        }),
        new MenuItem({
          name: 'menu.administration.users',
          route: '/administration/users',
          icon: 'sl:user-management',
          requiredPermissions: [Permission.UsersMenuRead],
          subItems: [],
          id: 'administration-users'
        }),
        new MenuItem({
          name: 'quotaStatistics.appName',
          icon: 'api',
          requiredPermissions: [Permission.QuotaSupport],
          subItems: [],
          route: '/quota-statistics',
          id: 'quota-statistics'
        }),
        new MenuItem({
          name: 'menu.automation',
          icon: 'sl:condition',
          requiredPermissions: [Permission.AutomationApp],
          subItems: [],
          route: '/automation',
          id: 'automation'
        })
      ]
    });

    const fleetsOverview = new MenuItem({
      name: 'menu.managedFleets',
      icon: 'dashboard',
      requiredPermissions: [Permission.ManagedFleets],
      subItems: [],
      route: '/managed-fleets',
      id: 'managedFleets'
    });

    const monitoring = new MenuItem({
      name: 'menu.monitoring.label',
      icon: 'sl:monitoring',
      requiredPermissions: [Permission.MonitoringRead],
      subItems: [],
      route: '/monitoring',
      id: 'monitoring'
    });

    const analysis = new MenuItem({
      name: 'menu.analysis',
      icon: 'insights',
      requiredPermissions: [Permission.AnalysisRead],
      subItems: [],
      route: '/analysis',
      id: 'analysis'
    });

    const dataStudio = new MenuItem({
      name: 'menu.dataStudio.dataStudio',
      icon: 'library_books',
      requiredPermissions: [Permission.DataStudioRead],
      subItems: [
        new MenuItem({
          name: 'menu.dataStudio.powerManagement',
          icon: 'bolt',
          requiredPermissions: [Permission.DataStudioPm],
          subItems: [],
          route: '/data-studio/power-management',
          id: 'data-studio-power-management'
        }),
        new MenuItem({
          name: 'menu.dataStudio.valueMatrix',
          icon: 'list_alt',
          requiredPermissions: [Permission.DataStudioMatrix],
          subItems: [],
          route: '/data-studio/value-matrix',
          id: 'data-studio-value-matrix'
        }),
        new MenuItem({
          name: 'menu.dataStudio.kpi',
          icon: 'insights',
          requiredPermissions: [Permission.DataStudioKpi],
          subItems: [],
          route: '/data-studio/kpi',
          id: 'data-studio-kpi'
        }),
        new MenuItem({
          name: 'menu.eventLog',
          icon: 'dynamic_form',
          requiredPermissions: [Permission.EventLogRead],
          subItems: [],
          route: '/data-studio/event-log',
          id: 'data-studio-event-log'
        })
      ],
      route: '/data-studio'
    });

    const tasksList = new MenuItem({
      name: 'menu.taskList',
      icon: 'sl:task-list',
      requiredPermissions: [Permission.TaskListRead],
      subItems: [],
      route: '/task-list',
      id: 'task-list'
    });

    const pinboards = new MenuItem({
      name: 'menu.pinboards.label',
      icon: 'view_comfy',
      requiredPermissions: [Permission.PinboardRead],
      subItems: [],
      route: '/pinboard',
      id: 'pinboards'
    });

    const slideshow = new MenuItem({
      name: 'menu.slideshow.label',
      icon: 'video_library',
      requiredPermissions: [Permission.SlideshowRead],
      subItems: [],
      route: '/slideshow',
      id: 'slideshows'
    });

    const reports = new MenuItem({
      name: 'reports.label',
      icon: 'description',
      requiredPermissions: [Permission.ReportRead],
      subItems: [],
      route: '/reports',
      id: 'reports'
    });

    const remoteConfig = new MenuItem({
      name: 'plantDetail.remoteConfiguration',
      icon: 'settings_remote',
      requiredPermissions: [Permission.PlantsRemoteConfig],
      subItems: [],
      route: '/remote-configuration',
      id: 'remote-configuration'
    });

    const logfileConfigurator = new MenuItem({
      name: 'menu.configurator',
      icon: 'code',
      requiredPermissions: [Permission.WebdriverRead],
      subItems: [],
      route: '/webdriver-configurator',
      id: 'webdriver-configurator'
    });

    this.sidenavStore.setSidenavItems([
      fleetsOverview,
      monitoring,
      analysis,
      dataStudio,
      tasksList,
      remoteConfig,
      administration,
      pinboards,
      slideshow,
      reports,
      logfileConfigurator
    ]);
  }
}
